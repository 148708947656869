body {
  --bs-body-font-family: "Nunito Sans" !important;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

:root {
  --primary-color: #6a4c91;
}

.primary-bg {
  background-color: var(--primary-color);
}

.primary-text {
  color: var(--primary-color);
}

.app-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  min-height: 65vh;
}

.nav-slider-bg {
  background-color: #664f8dde;
}

.primary-btn,
.primary-btn:hover,
.primary-btn:active,
.primary-btn:focus,
.primary-btn:active:focus {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.header-logo {
  width: 175px;
  height: 55px;
}

.app-grey {
  color: #a3a3a3;
}

.app-black {
  color: #555555;
}

.insights-card {
  background-color: #eadeec;
}

.insights-card-inner {
  background-color: #56a08f;
}

.form-bg {
  top: 226px;
  left: 119px;
  width: 1124px;
  height: 313px;
  background: #f9f9f9 0% 0% no-repeat padding-box;
  border-radius: 8px;
  opacity: 1;
}

.tableauViz {
  width: 500px !important;
}

.nav-active {
  color: white;
  border-radius: 10px;
  font-weight: bold;
}

.navbar {
  background-color: white;
}

.nav-link {
  color: black;
}

.hide {
  display: none;
}

.carousel-control-prev,
.carousel-control-next {
  display: none;
}

.underline {
  text-decoration: underline !important;
}

.home-homepage {
  position: relative;
  background-color: #8164a9;
}

.home-background-image {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.home-background-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 0.7;
  mix-blend-mode: multiply;
}

.home-text-overlay {
  position: absolute;
  top: 70%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  width: 75%;
}

.home-title,
.home-subtitle,
.home-message {
  color: white;
}

.home-title {
  font-size: 2.3rem;
  margin-bottom: 10px;
}

.home-subtitle {
  font-size: 1.8rem;
  margin-bottom: 20px;
}

.home-message {
  font-size: 1.3rem;
}

.nav-item .dropdown-item:focus,
.nav-item .dropdown-item:active {
  background-color: transparent !important;
  color: inherit !important;
}

.active > .page-link,
.page-link.active,
.page-link:hover,
.page-link:active {
  background-color: var(--primary-color);
  border-color: var(--primary-color);
}

.page-link {
  color: #555555;
}

.page-link:hover {
  color: white;
}

.disabled-row {
  opacity: 0.5;
  pointer-events: none;
}

.pvp-form-modal-100w {
  max-width: 95%;
}

.pvp-form-group {
  margin-bottom: 1rem;
}

.pvp-form-label {
  display: block;
  margin-bottom: 0.5rem;
  color: #333;
  font-weight: 700;
}

.pvp-form-control {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f8f9fa;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.pvp-form-row {
  margin-bottom: 1rem;
}

.low-confidence {
  background-color: #ffdddd;
  border: 1px solid #ffdddd;
}

.manual-review-view {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.coder-review-view {
  display: flex;
  flex-direction: column;
}

.back-button-container {
  display: flex;
  justify-content: flex-start;
}

.review-content {
  display: flex;
  flex: 1;
  gap: 10px;
  overflow: hidden;
}

.coder-review-content {
  display: flex;
  flex: 1;
  gap: 10px;
  overflow: hidden;
}

.pdf-viewer {
  flex: 0 0 40%; /* Take up 40% of the width */
  height: 100%;
  overflow: hidden;
  border: 1px solid #ced4da;
}

.pdf-viewer iframe {
  width: 100%;
  height: 100%;
  overflow: hidden;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

.pdf-viewer iframe::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.form-section {
  flex: 0 0 60%; /* Take up 60% of the width */
  height: 100%;
  overflow-y: auto;
  padding: 10px;
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
  margin-left: -10px;
}

.coder-form-section {
  flex: 0 0 100%; /* Take up 100% of the width */
  height: 100%;
  padding: 10px;
  /* margin-left: -10px; */
}

.form-section::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.pvp-form {
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: 8px;
}

.unique-id-value {
  padding-top: 5px;
}

.table thead th {
  background-color: #f1f1f1;
  font-weight: bold;
}

.table-bordered td,
.table-bordered th {
  border: 1px solid #dee2e6;
}

.editable-row {
  background-color: #e7f3ff;
}

.manual-review-table th,
.manual-review-table td {
  vertical-align: middle;
}

.manual-review-table .form-check-input {
  margin-top: 0;
}

.d-flex.justify-content-end.mt-3 {
  margin-top: 15px;
  display: flex;
  justify-content: flex-end;
}

button {
  display: inline-block;
  padding: 0.375rem 0.75rem;
  margin: 0 0.25rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #f8f9fa;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* Basic styles for input fields */
.manual-review-input {
  width: 100%;
  padding: 5px;
  border: 1px solid #ced4da;
  border-radius: 4px;
  box-sizing: border-box;
  font-weight: 600;
  color: #000000;
  background-color: rgba(255, 255, 255, 0.5);
}

/* Prefixed styles for table */
.coder-review {
  width: 100%;
  margin-bottom: 20px;
  border-collapse: collapse;
}

.coder-review th,
.coder-review td {
  padding: 8px;
  text-align: left;
  border: 1px solid #ddd;
}

.coder-review th {
  background-color: #f2f2f2;
}

.hide-row {
  display: none;
}

.pvp-patient-container {
  background-color: #d7f0f0;
  padding: 10px 10px !important;
  border-radius: 8px 8px 0px 0px;
}

.pvp-coder-ui-patient-container {
  background-color: #d7f0f0;
  padding: 10px 10px !important;
  border-radius: 8px;
  max-width: 100% !important;
  border: none !important;
}

.pvp-patient-container label,
.pvp-unique-id-title {
  font-weight: 600;
}

.pvp-unique-id-value {
  background-color: white;
  border-radius: 50px;
  width: 150px;
  padding-top: 2px;
  font-weight: bold;
  position: relative;
  left: -36px;
  top: -5px;
}

.pvp-unique-id-col {
  width: 50px;
}

.pvp-form-label-padding {
  padding-top: 2px;
}

.pvp-form * {
  font-size: 12px;
}

.pvp-sub-section h5 {
  color: #564276;
  font-size: 16px;
  font-weight: 600;
}

.pvp-form-edit-button {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  margin-left: 3px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.pvp-form-edit-button:hover,
.pvp-form-edit-button:focus,
.pvp-form-edit-button:active,
.pvp-form-edit-button:active:focus {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: var(--primary-color);
  border-color: var(--primary-color);
  margin-left: 3px;
}

.pvp-form-edit-icon,
.pvp-form-edit-icon:hover {
  color: white;
  font-size: 14px;
}

.pvp-form-edit-success {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: #56a08f;
  border-color: #56a08f;
  color: white;
  display: none;
}

.pvp-form-edit-success:hover,
.pvp-form-edit-success:active,
.pvp-form-edit-success:focus,
.pvp-form-edit-success:active:focus {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: #56a08f;
  border-color: #56a08f;
  color: white;
}

.pvp-form-edit-to-review {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: white;
  color: black;
  border: 1px solid #ccc;
  display: none;
}

.pvp-form-edit-to-review:hover,
.pvp-form-edit-to-review:active,
.pvp-form-edit-to-review:focus,
.pvp-form-edit-to-review:active:focus {
  border-radius: 4px;
  padding: 4px 6px;
  background-color: white;
  color: black;
  border: black;
}

.manual-review-form-table tbody tr td:nth-last-child() {
  display: flex;
}

.text-white {
  color: white;
}

.under-review {
  background-color: #f5f5f5;
}

.under-review td span.text-muted {
  color: #939393 !important;
}

.text-24 {
  font-size: 24px;
}
.text-20 {
  font-size: 20px;
}
.text-15 {
  font-size: 15px;
}
.text-13 {
  font-size: 13px;
}
.text-18 {
  font-size: 18px;
}
.pvp-list-table {
  font-size: 14px;
}
.icd-btn {
  height: 20px;
  font-size: 13px;
  width: 20px;
  padding: 0px 0px 0px 0px;
  border: 1px solid #333 !important;
  border-radius: 0;
}
.icd-btn-selected {
  border-color: #6f85be !important;
  background-color: #6f85be !important;
  border: 1px solid #333 !important;
  border-radius: 0;
  padding: 0px 7px;
  height: 20px;
  font-size: 13px;
  width: 20px;
}
tr.icd-selected-tr td {
  background: #c6d5f2;
  border: 1px solid#e3e3e3;
}
tr.icd-not-selected-tr td {
  background: #f4f4f4;
  border: 1px solid#e3e3e3;
}
.icd-btn-not-selected {
  border-color: #bcc3c3 !important;
  background-color: #bcc3c3 !important;
  border: 1px solid #333 !important;
  border-radius: 0;
  padding: 0px 7px;
  height: 20px;
  font-size: 13px;
  width: 20px;
}
.dropdown-menu {
  display: contents;
  margin-top: 30px;
}
tr.icd-auto-tr td {
  background: #d7f0f0;
  border: 1px solid#b9c2c2;
}
table.tbl-align-middle tr td {
  vertical-align: middle;
}
.pvp-back-to-list {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.pvp-detail-breadcrumb ol {
  margin-bottom: 0;
}
.pvp-coder-form {
  font-weight: 600;
  color: #000000;
  /* text-shadow: 0 0 black; */
}
.font-size-12 {
  font-size: 12px;
}
.font-size-14 {
  font-size: 14px;
}
.font-weight-600 {
  font-weight: 600;
}
/* .form-control::placeholder {
  color: #999;
}
select option[value="Select Evidence Type"] {
  color: #999 !important;
  font-weight: normal !important;
} */

/* Style for the value when the input has content */
/* .form-control:not(:placeholder-shown) {
  color: #000;
  font-weight: bold;
} */
.pvp-coder-ui-patient-container label {
  font-weight: 600;
}
select {
  -webkit-appearance: menulist; /* Default for Chrome */
  -moz-appearance: menulist; /* Default for Firefox */
  appearance: menulist; /* Default for other browsers */
  padding-right: 30px; /* Ensure there's enough space for the arrow */
  background-image: url("data:image/svg+xml;base64,PHN2ZyBmaWxsPSIjMDAwMDAwIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCAxMCAxMCI+PHBhdGggZD0iTTEuMyA0LjVsNC4yIDQuMiA0LjItNC4ySDEuM3oiLz48L3N2Zz4=");
  background-repeat: no-repeat;
  background-position: right 10px center;
  background-size: 12px;
}
.coder-tick {
  margin: 0px 0px 2px -5px;
  font-size: 13px;
}

.top-right-modal .modal-dialog {
  position: fixed;
  top: 10px;
  right: 10px;
  width: auto;
  margin: 0;
  z-index: 1055; /* Ensure it's above other content */
  pointer-events: auto; /* Ensure the modal is clickable */
}

.top-right-modal .modal-content {
  border-radius: 8px;
  overflow: hidden;
}

body.modal-open {
  overflow: visible !important; /* Allow background scrolling */
}
.modal-transparent {
  background-color: rgba(0, 0, 0, 0.1);
}

.top-right-modal .modal-header .btn-close {
  font-size: 12px; /* Adjust this value as needed */
  padding: 0.5rem;
}

.btn.disabled,
.btn:disabled,
fieldset:disabled .btn {
  background-color: #a58fc1;
  border-color: #a58fc1;
  opacity: #a58fc1;
}
.readonly-input {
  background-color: #f0f0f0; /* Light gray color */
  color: #333; /* Darker text color for readability */
  cursor: not-allowed; /* Indicates the field is not editable */
}

.dropdown-inline {
  position: absolute;
  z-index: 1000;
  background-color: white;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
  padding: 0;
}
.dropdown-inline .dropdown-item {
  padding: 8px 12px;
  cursor: pointer;
}
.dropdown-inline .dropdown-item:hover {
  background-color: #f1f1f1;
}

.pvp-form-edit-button-active {
  background-color: white !important;
  color: grey !important;
  border-color: lightgray !important;
}

.no-border{
  border: 0px;
}