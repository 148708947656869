/* RoleManagement.css */

/* Adjust indentation based on the level of nesting */
.ml-0 {
    margin-left: 0px;
  }
  
  .ml-1 {
    margin-left: 20px;
  }
  
  .ml-2 {
    margin-left: 40px;
  }
  
  .ml-3 {
    margin-left: 60px;
  }
  